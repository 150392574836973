.clothes__items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.clothes__section {
  display: flex;
  flex-direction: column;
}

.clothes__section-items {
  width: 100%;
  display: flex;
  padding: 0;
  margin-top: 63px;
}

.clothes__button {
  border: none;
  background-color: white;
  color: gray;
  cursor: pointer;
  padding-left: 20px;
  margin-bottom: 16px;
}

.clothes__button:hover {
  color: black;
}

.clothes__title {
  margin: 0 0 16px;
}
