.modal__item-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  /* align-items: stretch;
  justify-content: center; */
  overflow: hidden;
  background-color: white;
  max-width: 498px;
  width: 100%;
  max-height: 610px;
  border-radius: 16px;
}

.modal__item-image {
  max-width: 498px;
  max-height: 498px;
  object-fit: cover;
}

.modal__item_close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  background: transparent url(../../images/modalItem-close.svg) center no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.modal__item-name {
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;
  margin-left: 32px;
}

.modal__item_delete-button {
  font-size: 20px;
  padding-top: 20px;
  padding-right: 32px;
  color: rgba(255, 77, 0, 1);
  border: none;
  background-color: white;
}

.modal__item_delete-button_visible {
  visibility: visible;
}

.modal__item_delete-button_hidden {
  visibility: hidden;
}

.modal__item_delete-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.modal__item-weather {
  font-size: 20px;
  line-height: 24px;
  margin: 12px auto 32px 32px;
}

.modal__wrap {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 0;
}
