.weather__card {
  position: relative;
}

.weather__temp {
  position: absolute;
  color: white;
  font-weight: 700;
  font-family: "CabinetGrotesk-Bold";
  font-size: 36px;
  line-height: 40px;
  margin: 20px 16px;
}

.weather__image {
  max-width: 100%;
  height: 80px;
}
