.header {
  /* background-color: lightseagreen; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin: 32px 0 28px;
  max-width: 1360px;
  font-size: 20px;
  line-height: 24px;
}

.header__logo-group {
  display: flex;
  gap: 20px;
  align-items: center;
}

.header__avatar-group {
  display: flex;
  gap: 20px;
  align-items: center;
}

.header__button {
  border-width: 0cap;
  background-color: white;
  cursor: pointer;
}

.header__button:hover {
  color: blue;
}

.header__avatar-logo {
  display: flex;
  gap: 20px;
  align-items: center;
  border-radius: 30px;
  height: 56px;
  width: 56px;
}
