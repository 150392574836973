.sidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: last baseline;
  height: 40px;
  margin-top: 47px;
}

.sidebar__user-data {
  display: flex;
  padding-bottom: 4px;
}

.sidebar__name {
  font-size: 20px;
  line-height: 25px;
  padding-top: 16px;
  padding-left: 16px;
  min-width: 280px;
}

.sidebar__avatar {
  width: 56px;
  height: 56px;
  align-items: center;
  border-radius: 30px;
}

.sidebar__button {
  border-width: 0cap;
  background-color: white;
  cursor: pointer;
  padding-left: 0;
}
