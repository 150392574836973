.modal__delete-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 670px;
  height: 280px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  position: relative;
}

.modal__delete-text {
  text-align: center;
  width: 367px;
  height: 48px;
  margin: 60px auto 0;
  font-size: 20px;
  line-height: 24px;
}

.modal__delete-button {
  font-size: 20px;
  cursor: pointer;
  border: none;
  background-color: white;
}

.modal__delete-confirm {
  margin-top: 40px;
  color: rgba(255, 77, 0, 1);
}

.modal__delete-cancel {
  margin-top: 24px;
  color: rgba(0, 0, 0, 1);
}
