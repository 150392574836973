@font-face {
  src: url("./fonts/CabinetGrotesk-Bold.woff") format("woff"),
    url("./fonts/CabinetGrotesk-Bold.woff2") format("woff2");
  font-family: "CabinetGrotesk-Bold";
  font-weight: 700;
}

@font-face {
  src: url("./fonts/CabinetGrotesk-Medium.woff") format("woff"),
    url("./fonts/CabinetGrotesk-Medium.woff2") format("woff2");
  font-family: "CabinetGrotesk-Medium";
  font-weight: 700;
}

@font-face {
  src: url("./fonts/CabinetGrotesk-Regular.woff") format("woff"),
    url("./fonts/CabinetGrotesk-Regular.woff2") format("woff2");
  font-family: "CabinetGrotesk-Regular";
  font-weight: 700;
}
