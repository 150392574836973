.card__section {
  margin: 40px;
  margin-left: 0;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 34px;
}

.card__container {
  display: flex;
  align-items: center;
  position: relative;
}

.card__name {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 17px;
  left: 50%;
  background-color: white;
  border-radius: 4px;
  padding: 2px 12px;
  max-width: 95px;
  transform: translateX(-50%);
  margin: auto;
  margin-right: 10px;
}

.card__image {
  width: 325px;
  height: 328px;
  border-radius: 20px;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.card__text {
  display: flex;
  font-size: 20px;
  line-height: 25px;
}

.card__like-button {
  background: transparent url("../../images/empty-heart.svg") center no-repeat;
  position: absolute;
  width: 21px;
  height: 18px;
  border: none;
  cursor: pointer;
  top: 20px;
  left: calc(65% + 10px);
}

.card__like-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.card__like-button_liked {
  background: transparent url("../../images/filled-heart.svg") center no-repeat;
  cursor: pointer;
}

.card__like-button_hidden {
  display: none;
}
