.page {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 100vh;
  font-family: "CabinetGrotesk-Bold";
  font-size: 16px;
  line-height: 20px;
}
